import React from 'react'
import { Hero, SectionFull, Copy, Sidebar } from '@website2018/da-dobsonville';

import Asides from "../Asides/Asides"
import Campaigns from "../Campaigns/Campaigns"
import ListWrapper from "../Nav/List"
import ListItem from "../Nav/ListItem"
import MetaHeader from "../Meta/MetaHeader";

import { Link } from 'react-router-dom';
import { noComma } from '../../common/utils';
import { decode } from 'he';

const CampaignsTemplate = (props) => {
  const { latest, archive, page:p } = props;
  return ([
    <MetaHeader p={p} />,
    <Hero
      imageUrl={p.hero && p.hero.background_image ? p.hero.background_image.url : null}
      title={decode(p.hero && p.hero.headline ? p.hero.headline : p.title.rendered)}
      bgPosition={ p.hero && p.hero.focus_point && noComma(p.hero.focus_point) }
    />,
    p.content.rendered &&
    <SectionFull mt={"none"} mb="medium">
      <div className={"col-lg-7"}>
        <Copy intro html={p.content.rendered} />
      </div>
    </SectionFull>,

    <SectionFull>
      <div className={"col-md-12"}>
        <Campaigns campaigns={latest} grid={"col-md-6"} title={"Latest Campaigns"} />
      </div>
    </SectionFull>,

    <SectionFull
      mt={"medium"}
      title="Other Campaigns">
      <div className={"col-lg-7"}>
        <ListWrapper>
            { archive.map(r =>
                r.external.status
                ? <ListItem key={r.slug} link title={<a href={r.external.link} target={"_blank"}>{decode(r.title.rendered)}</a>} />
                : <ListItem key={r.slug} link title={<Link to={`/campaigns/${r.slug}`}>{decode(r.title.rendered)}</Link>} />
              )
            }
        </ListWrapper>
      </div>

      <Sidebar>
        { p.asides &&
          <Asides asides={p.asides} />
        }
      </Sidebar>
    </SectionFull>
  ])
}

CampaignsTemplate.defaultProps = {}

export default CampaignsTemplate
